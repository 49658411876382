
import Vue from "vue";
import rules from "@/services/helpers/validation-rules";
import API from "@/api/API";
import store from "@/store";

export default Vue.extend({
  name: "Create",

  data: () => ({
    rules,
    model: {
      accept_ata: true as boolean
    },
    selects: {
      serviceTypes: [] as Array<SelectComponentInterface>
    } as any,
    errorMessages: {} as { [value: string]: Array<string> }
  }),

  async beforeRouteEnter(to, from, next) {
    try {
      const typesList = await API.serviceTypes().getList();
      next(vm => {
        vm.selects.serviceTypes = typesList;

        vm.$nextTick(() => {
          vm.fullLoaded = true;
        });
      });
    } catch (e) {
      await store.dispatch("alert/showError", e.message);
    }
  },

  methods: {
    async submit(): Promise<void> {
      try {
        if ((this.$refs.form as Vue).validate()) {
          await this.$API.purposeOfUse().create(this.model);
          await this.$router.push("/purpose-of-use");
          await this.$store.dispatch(
            "alert/showSuccess",
            this.$t("global_alert.successful_create")
          );
        }
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;

          setTimeout(() => {
            this.errorMessages = {};
          }, 2000);
        }
        await this.$store.dispatch("alert/showError", e.message);
      }
    }
  }
});
